export enum DatasourceSettingType {
    Checkbox = 'checkbox',
    Number = 'number',
    Text = 'text',
    Device = 'device',
}
export interface DatasourceSettingDefinition {
    id: string;
    type: DatasourceSettingType;
    name: string;
    description: string;
    defaultValue: string | number | boolean;
}
