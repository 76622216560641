import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Datasource, DatasourceConfig } from '../datasource';
import { DatasourceType } from '../datasource-type';

export class DatasourceTime extends Datasource {
    constructor(config: DatasourceConfig) {
        super(DatasourceType.TIME, config);
        this.topicOptions = [
            {
                direction: 'subscribe',
                name: 'datasource.time.date_and_time',
                group: 'datasource.time.date_and_time',
                topicSegments: ['full_string_value'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.time.date',
                group: 'datasource.time.date_and_time',
                topicSegments: ['date_string_value'],
            },
            {
                direction: 'subscribe',
                name: 'label.time',
                group: 'datasource.time.date_and_time',
                topicSegments: ['time_string_value'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.time.timestamp.seconds',
                group: 'datasource.time.date_and_time',
                topicSegments: ['timestamp'],
            },
            {
                direction: 'subscribe',
                name: 'datasource.time.timestamp.milliseconds',
                group: 'datasource.time.date_and_time',
                topicSegments: ['numeric_value'],
            },
        ];
    }

    async connect(_) {
        const refresh = this.settings.refresh;
        if (refresh) {
            this.publishUpdatedTime();
            interval(refresh * 1000)
                .pipe(takeUntil(this.disconnect$))
                .subscribe(() => this.publishUpdatedTime());
        }
    }

    private publishUpdatedTime() {
        const date = new Date();
        const newData = {
            numeric_value: date.getTime(),
            timestamp: Math.floor(date.getTime() / 1000),
            full_string_value: date.toLocaleString(),
            date_string_value: date.toLocaleDateString(),
            time_string_value: date.toLocaleTimeString(),
            date_object: date,
        };
        this.data$.next(newData);
    }
}
