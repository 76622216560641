import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { merge } from 'src/app/shared/common';
import { CanDatabase } from 'src/app/shared/models/can-database';
import { Device } from 'src/app/shared/models/device';
import { v4 as uuidv4 } from 'uuid';
import { DashboardPageService } from '../service/dashboard-page.service';
import {
    DatasourceType,
    getDatasourceSettingDefinitions,
} from './datasource-type';
import { SandboxDatasource } from './sandbox/datasource';
export interface DatasourceConfig {
    uuid?: string;
    name: string;
    settings: any;
    type: DatasourceType;
}

export type DatasourceConnectContext = {
    dashboardPageService: DashboardPageService; // TODO: remove
    busNames: { [key: string | number]: string | number };
    devices: { [key: string]: Device };
    httpClient: HttpClient;
    data$: Observable<any>;
    canDatabases: CanDatabase[];
};

export abstract class Datasource {
    uuid: string;
    name: string;
    settings: any = {};
    data$ = new Subject<object>();
    protected disconnect$ = new Subject();
    sandboxDatasource: SandboxDatasource;
    topicOptions: DatasourceTopicOption[] = [];

    constructor(
        public type: DatasourceType,
        { uuid, name, settings }: DatasourceConfig,
    ) {
        if (!uuid) {
            uuid = uuidv4();
        }
        this.uuid = uuid;
        this.name = name;
        const defaultSettings = {};
        for (const setting of getDatasourceSettingDefinitions(this.type)) {
            defaultSettings[setting.id] = setting.defaultValue;
        }
        this.settings = merge(defaultSettings, settings);
        this.sandboxDatasource = new SandboxDatasource(this.name);
    }

    abstract connect(context: DatasourceConnectContext): Promise<void>;

    async disconnect() {
        this.disconnect$.next();
    }

    send(topic: string, message: Uint8Array | string): Promise<void> {
        throw Error('This datasource does not support sending data');
    }

    build(): DatasourceConfig {
        return {
            uuid: this.uuid,
            name: this.name,
            type: this.type,
            settings: Object.assign({}, this.settings),
        };
    }
}

export interface DatasourceTopicOption {
    direction: 'subscribe' | 'publish';
    name: string;
    group: string;
    topicSegments: string[];
}
